.documentationContent {
    height: 100%;
    width: 90vw;
    display: flex;
    overflow: auto;
    
}

.documentationNav {
    position: sticky;
    width: 17%;
    min-width: 250px;
    margin-right: 20px;
}

.documentationNav ul {
    list-style-type: none;
}

.documentationNav li {
    width: 100%;
    height: 50px;
    border-left: 3px solid #3D405A;
    font-weight: bold;
    padding-left: 0px;
}

.documentationNav button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #3D405A;
}

.documentationNav li.selected {
    border-left: 3px solid rgb(241, 144, 33);
}

.documentation {
    width: 85%;
    margin-bottom: 20px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 50%;
}

.documentation-scroll {
    width: 100%;
    height: 500px;
    padding-bottom: 20px;
}

.documentation-scroll h3 {
    font-size: 20px;
}

.documentation-scroll p {
    font-size: 14px;
}

.documentation-scroll pre {
    font-family: 'Courier';
    word-wrap: break-word;
    white-space: pre-wrap;
}

.documentation-scroll * {
    font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.documentation h1 {
    width: fit-content;
    padding-bottom: 10px;
    border-bottom: 3px solid rgb(241, 144, 33);
    margin-bottom: 50px;
}

.documentation h2 {
    width: fit-content;
    padding-bottom: 10px;
    border-bottom: 3px solid rgb(241, 144, 33);
    margin-bottom: 50px;
}

.documentation p{
    color: #3D405A;
    line-height: 30px;
    font-size: 14px;
}

.documentation ol{
    color: #3D405A;
    line-height: 30px;
    font-size: 14px;
}

.documentation .img-full img{
    width: 100%;
}

.documentation .img-architecture {
    display: flex;
    justify-content: center;
}

.documentation .img-architecture img {
    width: 50%;
}

.documentation .code {
    margin-left: 50px;
}
