.dashboardWrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.dashboardSummary {
    margin-bottom: 20px;
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    color: #3D405A;
    background-color: rgba(255, 255, 255, 0.8);
}

.dashboardSummaryContainer {
    display: flex;
}

.dashboardSummarySection {
    padding: 0px 20px 20px 20px;
    font-size: 20px;
}

.number {
    font-size: 80px;
    font-weight: bold;
}

.dashboardBtn{
    width: 100%;
    color: #3D405A;
    font-weight: bold;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
}

.dashboardTable {
    position: relative;
}

.dashboardTableOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.dashboardLoaderContainer {
    width: fit-content;
    position: absolute;
    top: 20%;
    left: 50%;
}

.dashboardTableLoaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}